import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function KamdulaPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/kamdula-landing-page-2.png") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title"><b>The Safe Harbour</b></h1>
              <h3 className="description"><b>
                Secure Your Wealth Across Borders.
                </b></h3>
              <br />
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline
                href="mailto:info@kamdula.com" target="_blank" rel="noreferrer"
              >
                Contact Us
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default KamdulaPageHeader;
