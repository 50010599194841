import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
    Label,
} from "reactstrap";

// core components
import KamdulaDropdownNavbar from "components/Navbars/KamdulaDropdownNavbar.js";
import KamdulaPageHeader from "components/Headers/KamdulaPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

function Kamdula() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <KamdulaDropdownNavbar />
            <KamdulaPageHeader />
            <div className="wrapper">
                <div className="section text-center landing-section">
                    <Container>
                        <Row>
                            <Col style={{ color: 'black' }} className="ml-auto mr-auto" md="8">
                                <h2 className="title"><b>Who we are and what we do</b></h2>
                                <h5><b>
                                    We are a London-based fintech founded by a former central banker and a fintech developer. Our goal is to secure your wealth using trusted metals and global locations, shielding it from economic or legal crises in your country. Instantly and securely buy and sell gold bullions, insured by a UK bank, and stored in accredited, audited vaults.
                                    </b></h5>
                                <br />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-bank" />
                                    </div>
                                    <div style={{ color: 'black' }}>
                                        <h4 className=""><b>Secure</b></h4>
                                        <p className="">
                                           <b>
                                            We secure your wealth into gold and silver bullion in secure vaults in London, Zurich and Istanbul. You can get your bullion whenever you want or prefer to be delivered to your address.
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-money-coins" />
                                    </div>
                                    <div style={{ color: 'black' }}>
                                        <h4 className="info-title"><b>Liquid</b></h4>
                                        <p><b>
                                            You can instantly buy and sell your gold and silver bullion at the market price. No hidden costs, no extra payments or margins.
                                            </b></p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-single-copy-04" />
                                    </div>
                                    <div style={{ color: 'black' }}>
                                        <h4 className="info-title"><b>Insured</b></h4>
                                        <p><b>
                                            All vaults are insured by a UK bank and audited by a global audit firm every six months, and the bullion is LBMA accredited.
                                            </b></p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-air-baloon" />
                                    </div>
                                    <div style={{ color: 'black' }}>
                                        <h4 className="info-title"><b>Tax-free</b></h4>
                                        <p><b>
                                            We offer VAT-free and Capital Gain Tax-free gold and silver bullion minted by the Royal Mint. You earn 20% when you invest in silver with us.
                                            </b></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section landing-section">
                    <div
                        className="contactus-1 section-image"
                        style={{
                            backgroundImage:
                                "url(" + require("assets/img/kamdula-bank.png") + ")",
                        }}
                    >
                        <Container>
                            <Row style={{ justifyContent: "center" }}>
                                <Col className="ml-auto mr-auto" md="5">
                                    <Card className="card-contact no-transition">
                                        <CardTitle className="text-center" tag="h3">
                                            Contact Us
                                        </CardTitle>
                                        <Row>
                                            <Col className="mr-auto" md="12">
                                                <Form id="contact-form" method="POST" role="form" action="https://formspree.io/f/xwkdkgjo">
                                                    <CardBody>
                                                        <Row>
                                                            <Col md="12">
                                                                <FormGroup className="label-floating">
                                                                    <label className="control-label">
                                                                        Full Name
                                                                    </label>
                                                                    <Input
                                                                        aria-label="Full Name Here..."
                                                                        autoComplete="name"
                                                                        placeholder="Full Name Here..."
                                                                        type="text"
                                                                        id="name"
                                                                        name="name"
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <FormGroup className="label-floating">
                                                            <label className="control-label">
                                                                Email address
                                                            </label>
                                                            <Input
                                                                aria-label="Email Here..."
                                                                autoComplete="email"
                                                                placeholder="Email Here..."
                                                                type="email"
                                                                id="email"
                                                                name="_replyto"
                                                                required
                                                            ></Input>
                                                        </FormGroup>
                                                        <FormGroup className="label-floating">
                                                            <label className="control-label">
                                                                Your message
                                                            </label>
                                                            <Input
                                                                id="message"
                                                                name="message"
                                                                rows="6"
                                                                type="textarea"
                                                                required
                                                            ></Input>
                                                        </FormGroup>
                                                        <Row justifyContent="center">
                                                            <Col md="12">
                                                                <Button
                                                                    className="pull-right"
                                                                    color={'primary'}
                                                                    type="submit"
                                                                >
                                                                    Send Message
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterWhite />
        </>
    );
}

export default Kamdula;
